// extracted by mini-css-extract-plugin
export var YouTubeListingItem = "YouTubeListingItem-module--YouTubeListingItem--xLlnO";
export var abstract = "YouTubeListingItem-module--abstract--+MYaK";
export var nsfw = "YouTubeListingItem-module--nsfw--NT4sl";
export var nsfwMessage = "YouTubeListingItem-module--nsfw-message--lRp3H";
export var show = "YouTubeListingItem-module--show--WIZVk";
export var coverImage = "YouTubeListingItem-module--coverImage--BDMwP";
export var contentAuthors = "YouTubeListingItem-module--contentAuthors--iQKZS";
export var category = "YouTubeListingItem-module--category--EvC9Y";
export var collection = "YouTubeListingItem-module--collection--eVXb3";
export var meta = "YouTubeListingItem-module--meta--FtKl8";
export var nsfwTag = "YouTubeListingItem-module--nsfwTag--b3bj+";