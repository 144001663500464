import React from "react"
import YouTubeListingItem from "../YouTubeListingItem"
import { motion, AnimatePresence } from "framer-motion"
import * as style from "./YouTubeListing.module.sass"
import clsx from "clsx"

const YouTubeListing = ({ skipPostID, posts }) => {

	const itemAnimationStates = {
		initial: {
			opacity: 0,
			y: -50
		},
		animate: {
			opacity: 1,
			y: 0,
		}
	}

	return (

		<div className={style.YouTubeListing}>
			<AnimatePresence animateOnMount>
				{posts.filter(post => post.node.frontmatter.title.length > 0).map(({ node: post }, i) => {

					if (post.id !== skipPostID) {

						const { frontmatter, fields } = post
						const isFullArticle = frontmatter.abstract ? true : false

						const rootClassName = clsx({
							[style.doubleColumn]: isFullArticle,
						})

						return (
							<motion.div className={rootClassName} key={fields.slug} transition={{ delay: i * .07 }} variants={itemAnimationStates} initial="initial" animate="animate">
								<YouTubeListingItem {...fields} {...frontmatter} />
							</motion.div>
						)
					
					}
					return null
				
				})}
			</AnimatePresence>
		</div>

	)

}

export default YouTubeListing
